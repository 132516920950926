import React, { useState } from "react";
import styled from "@emotion/styled";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import { Card, Input } from "@theme-ui/components";
import ProductsList from "./ProductsList";
import { InputWrap, IconLeft } from "../../app/DSL/components/form/StyledInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { categories } from "../../app/context/catalogue/categories";
import { types } from "../../app/context/catalogue/types";
import { customStyles } from "../../app/forms/Form";
import { Heading } from "rebass";

export default function Catalogue() {
  const [category, setCategory] = useState();
  const [type, setType] = useState();
  //TODO: release brand field
  //const [brand, setBrand] = useState();
  const [search, setSearch] = useState();

  return (
    <div>
      <PageHeaderContainer>Catalogue</PageHeaderContainer>
      <HeaderCard>
        <div className="top">
          <div>
            <Heading as="h2" className="h2">
              Welcome to
            </Heading>
            <Heading as="h1" className="h1">
              Brochure App
            </Heading>
          </div>
        </div>
        <div className="bottom"></div>
        <div className="right">
          <img src="/assets/grouped-products.png" alt="" />
        </div>
      </HeaderCard>
      <Card variant="small">
        <Filters>
          <Grid>
            <InputWrap icon="left">
              <IconLeft>
                <FontAwesomeIcon icon={["fas", "search"]} />
              </IconLeft>
              <Input
                className="input"
                placeholder="Looking for something?"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputWrap>
            <InputWrap>
              <Select
                styles={customStyles}
                isClearable
                isSearchable
                options={categories}
                value={category}
                placeholder="Category"
                onChange={(option) => setCategory(option)}
              />
            </InputWrap>
            <InputWrap>
              <Select
                styles={customStyles}
                isClearable
                isSearchable
                options={types}
                value={type}
                placeholder="Type"
                onChange={(option) => setType(option)}
              />
            </InputWrap>
          </Grid>
        </Filters>
        {/* <InputWrap className="wrap" style={{ margin: "1em 0" }}>
            <Label>Brand</Label>
            <Select
            styles={customStyles}
            isClearable
            isSearchable
            options={brands[category?.value]}
            value={brand}
            placeholder="Select a brand"
            onChange={(option) => {
              setBrand(option);
            }}
            />
          </InputWrap> */}

        <ProductsList
          category={category}
          search={search}
          type={type}
          //brand={brand}
        />
      </Card>
    </div>
  );
}

const Filters = styled.div`
  position: sticky;
  top: 0;
  background: white;
  z-index: 40;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  grid-template-rows: 3fr;
  column-gap: 1em;
  row-gap: 1em;
  align-items: start;
  margin-bottom: 1em;
  @media (max-width: 768px) {
    row-gap: 0px;
    grid-template-columns: 1fr;
  }
`;

const HeaderCard = styled(Card)`
  margin-bottom: 1em;
  width: auto;
  padding: 0;
  position: relative;

  @media (max-width: 768px) {
    width: 92vw;
  }

  @media (max-width: 425px) {
    width: auto;
  }

  @media (max-width: 600px) {
    img {
      display: none;
    }
  }

  .top {
    background-image: url("/assets/bg.png");
    padding: 5em;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    * {
      color: white;
      font-weight: normal;
    }
    h2 {
      text-transform: uppercase;
      z-index: 2;
      @media (max-width: 768px) {
        font-size: 2em;
      }
    }
    h1 {
      font-size: 5em;
      position: relative;
      z-index: 2;
      @media (max-width: 1024px) {
        font-size: 4em;
      }
      @media (max-width: 768px) {
        font-size: 3em;
      }
    }
    @media (max-width: 1024px) {
      padding: 5em 2em;
    }
  }

  .bottom {
    height: 70px;
  }
  .right {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      height: 100%;
    }
  }
`;
