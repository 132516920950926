import React, { useMemo } from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { useSliders } from "../../app/context/sliders";
import { Image } from "@theme-ui/components";
import SliderItem from "./SliderItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Ring } from "react-awesome-spinners";
import colours from "../../app/DSL/styles/colours";
import { primary } from "../../app/DSL/theme";

export default function Slider() {
  const { sliders } = useSliders();
  const sliderList = useMemo(
    () =>
      sliders
        ? sliders
            .filter((s) => s?.platform?.value === "brochure")
            .filter((s) => s.isPublished)
        : [],
    [sliders]
  );

  const [init, setInit] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => setInit(true), 500);
  }, []);

  if (!init)
    return (
      <div style={{ margin: "0 auto", width: "fit-content" }}>
        <Ring color={colours[primary].shade["500"]} />
      </div>
    );
  return sliders ? (
    sliderList.length > 0 ? (
      <div style={{ maxWidth: "1200px", margin: "auto" }}>
        <Carousel
          arrows
          infinite
          autoPlay={sliderList.length > 1 ? 6000 : 100000000000}
          animationSpeed={1000}
          slidesPerPage={1}
          centered
          arrowLeft={<FontAwesomeIcon icon={["fal", "angle-left"]} />}
          arrowRight={<FontAwesomeIcon icon={["fal", "angle-right"]} />}
          addArrowClickHandler
        >
          {sliderList.map((s, idx) => (
            <SliderItem slider={s} key={idx} />
          ))}
        </Carousel>
      </div>
    ) : (
      <SpotlightCard>
        <div className="text">
          <h1>{process.env.REACT_APP_SITE_TITLE}</h1>
          <p>
            The Brochure app has been improved to serve the sales team on a
            whole new level. See all the products in one place, share them with
            your surgeon and create enquiries - Easier than ever before!
          </p>
        </div>
        <Image src="/logo.svg" className="not-mobile" />
      </SpotlightCard>
    )
  ) : (
    <div style={{ margin: "0 auto", width: "fit-content" }}>
      <Ring color={colours[primary].shade["500"]} />
    </div>
  );
}

const SpotlightCard = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 1em;
  align-items: center;
  img {
    width: 50%;
    margin: auto;
  }
  .text {
    padding: 2em;
    padding-left: 4em;
    h1 {
      margin: 0;
      font-size: 4em;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    p {
      margin: 0;
      margin-bottom: 2rem;
    }
  }
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
    .text {
      h1 {
        margin: 0;
        font-size: 2em;
      }
    }
  }
`;
