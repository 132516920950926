import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faCog,
	faTh,
	faTrash,
	faPencilAlt,
	faEye,
	faCheckCircle,
	faTimesCircle,
	faUserCircle,
	faSearch,
	faThumbsUp,
	faCheck,
	faLock,
	faStar,
	faQuestionCircle,
	faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import {
	faSignOut,
	faHourglassHalf,
	faUserAlt,
	faDownload,
	faFilePdf,
	faPlay,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
	faCog,
	faTh,
	faSignOut,
	faTrash,
	faPencilAlt,
	faEye,
	faCheckCircle,
	faTimesCircle,
	faHourglassHalf,
	faUserAlt,
	faUserCircle,
	faSearch,
	faThumbsUp,
	faCheck,
	faLock,
	faStar,
	faQuestionCircle,
	faDownload,
	faFilePdf,
	faPlay,
	faChevronLeft
);

export {
	faCog,
	faTh,
	faSignOut,
	faTrash,
	faPencilAlt,
	faEye,
	faCheckCircle,
	faTimesCircle,
	faHourglassHalf,
	faUserAlt,
	faUserCircle,
	faSearch,
	faThumbsUp,
	faCheck,
	faLock,
	faStar,
	faQuestionCircle,
	faFilePdf,
	faPlay,
	faChevronLeft,
};
