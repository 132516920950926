import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import styled from "@emotion/styled";

import { Ring } from "react-awesome-spinners";
import { Button, Image } from "@theme-ui/components";
import { primary } from "../../theme";

export default function ImageUpload({
	cropper,
	aspectRatio,
	dimensions,
	centered,
	upload,
	uploading,
	image,
	fullwidth,
	name,
}) {
	const [fullImage, setFullImage] = useState();
	const [loading, setLoading] = useState(false);
	const fileInput = React.createRef();

	const handleDrop = (e) => {
		e.stopPropagation();
		e.preventDefault();
		const dt = e.dataTransfer;
		const files = dt.files;
		handleFile(files);
	};

	const handleDrag = (e) => {
		e.stopPropagation();
		e.preventDefault();
	};

	const handleFile = (files) => {
		setLoading(true);
		const reader = new FileReader();
		reader.addEventListener("load", () => {
			setFullImage(reader.result);
		});
		try {
			reader.readAsDataURL(files[0]);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<DropArea
			fullwidth={fullwidth}
			centered={centered}
			dimensions={dimensions}
			onDrop={handleDrop}
			onDragEnter={handleDrag}
			onDragOver={handleDrag}
		>
			{(loading || uploading) && (
				<Loader dimensions={dimensions} fullwidth={fullwidth}>
					<Ring color="blue" />
				</Loader>
			)}
			{image && !fullImage ? (
				<div>
					<Image src={image} style={{ ...dimensions, marginBottom: "20px" }} />

					<FileInput
						id={name ? name : "file"}
						type="file"
						accept="image/*"
						onChange={() => handleFile(fileInput.current.files)}
						ref={fileInput}
					/>
					<FileInputLabel htmlFor={name ? name : "file"}>
						Choose another image
					</FileInputLabel>
				</div>
			) : fullImage ? (
				<div>
					<Cropper
						ready={() => setLoading(false)}
						ref={cropper}
						src={fullImage}
						style={dimensions ? dimensions : { height: 400, width: 400 }}
						// Cropper.js options
						aspectRatio={aspectRatio ? aspectRatio : 1 / 1}
						guides={true}
					/>
					{upload && (
						<Button
							style={{
								marginTop: "10px",
							}}
							onClick={() => upload().then(() => setFullImage(null))}
						>
							Upload
						</Button>
					)}
				</div>
			) : (
				!loading &&
				!uploading && (
					<>
						<p>Drag and drop an image here</p>
						<p>Or</p>
						<FileInput
							id={name ? name : "file"}
							type="file"
							accept="image/*"
							onChange={() => handleFile(fileInput.current.files)}
							ref={fileInput}
						/>
						<FileInputLabel htmlFor={name ? name : "file"}>
							Choose a file
						</FileInputLabel>
					</>
				)
			)}
		</DropArea>
	);
}

const FileInput = styled.input`
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;
const FileInputLabel = styled.label`
	display: block;
	max-width: 200px;
	margin: auto;
	cursor: pointer;
	justify-content: center;
	padding-bottom: calc(0.7em - 1px);
	padding-left: 1em;
	padding-right: 1em;
	padding-top: calc(0.7em - 1px);
	text-align: center;
	white-space: nowrap;
	background-color: ${(props) => props.theme.colors[primary].shade["700"]};
	color: ${(props) => props.theme.colors[primary].font["700"]};
	font-weight: bold;
	:hover {
		background-color: ${(props) => props.theme.colors[primary].shade["900"]};
	}
	:focus {
		outline: 0;
	}
	border-radius: 4px;
`;

const DropArea = styled.div`
	width: ${(props) =>
		props.fullwidth
			? "calc(100% - 4.5em)"
			: props.dimensions
			? props.dimensions.width
			: "400px"};
	margin: ${(props) => (props.centered ? "auto" : 0)};
	min-height: ${(props) =>
		props.dimensions ? props.dimensions.height : "400px"};
	padding: 2em;
	border: 5px dashed lightgrey;
	border-radius: 5px;
	display: grid;
	align-content: center;
	justify-content: center;
	text-align: center;
	margin-bottom: 1em;
`;
const Loader = styled.div`
	width: ${(props) =>
		props.fullwidth
			? "calc(100% - 4.5em)"
			: props.dimensions
			? props.dimensions.width
			: "400px"};
	height: ${(props) => (props.dimensions ? props.dimensions.height : "400px")};
	position: absolute;
	display: grid;
	align-items: center;
	justify-items: center;
	z-index: 5;
`;
