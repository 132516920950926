import React, { useState } from "react";
import styled from "@emotion/styled";
import { Card, Image, Button } from "@theme-ui/components";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";

import EnquiriesTable from "./EnquiriesTable";

import { NavLink } from "react-router-dom";
import EnquiryDetails from "../../Enquiries/EnquiryDetails";

//TODO: Duplicated

export default function ManageData() {
  const [view, setView] = useState("list");

  const [item, setItem] = useState();
  const toggleView = () => {
    setItem(null);
    if (view !== "list") {
      setView("list");
    } else {
      setView("create");
    }
  };
  const setDetails = (itemToSet) => {
    setItem(itemToSet);
    setView("details");
  };
  /* 
  const goBack = () => {
    setView("list");
    setItem(null);
  }; */
  return (
    <div>
      <PageHeaderContainer>Enquiries</PageHeaderContainer>
      <MobileMessage>
        <Card>
          <div style={{ textAlign: "center" }}>
            <Image
              src="/assets/desktop.svg"
              style={{ width: "45%", margin: "auto" }}
            />
            <h4> Please go to the desktop version to manage enquiries</h4>
            <NavLink to="/dashboard">
              <Button>Back to dashboard</Button>
            </NavLink>
          </div>
        </Card>
      </MobileMessage>
      <Grid>
        <Card>
          {view === "list" ? (
            <BlockHeaderContainer
              title={view === "list" ? `Enquiryies List` : `${view} enquiry`}
            />
          ) : (
            <BlockHeaderContainer
              actionType="button"
              action={toggleView}
              buttonText={`Back to enquiries`}
              title={view === "list" ? `Enquiries List` : `${view} enquiry`}
            />
          )}
          {
            {
              list: <EnquiriesTable viewEnquiry={(item) => setDetails(item)} />,
              details: <EnquiryDetails item={item} />,
            }[view]
          }
        </Card>
      </Grid>
    </div>
  );
}
const MobileMessage = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (max-width: 800px) {
    display: none;
  }
`;
