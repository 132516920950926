import React from "react";
import styled from "@emotion/styled";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import { Card, Image } from "@theme-ui/components";
import EnquiriesTable from "../Enquiries/EnquiriesTable";
import FeaturedProducts from "./FeaturedProducts";
import { useHistory } from "react-router-dom";
import Slider from "./Slider";
import InfoRequest from "./InfoRequest";

const Dashboard = () => {
  var history = useHistory();
  return (
    <div>
      <PageHeaderContainer image>
        <Image
          src={`/assets/${
            process.env.REACT_APP_LOGO
              ? process.env.REACT_APP_LOGO
              : "long-logo.svg"
          }`}
          width="520px"
        />
      </PageHeaderContainer>
      <SliderGrid>
        <Card>
          <Slider />
        </Card>
        <Card>
          <BlockHeaderContainer title="Have a question?" />
          <p style={{ fontSize: "14px" }}>
            Send an internal request for more information about specific
            products within {process.env.REACT_APP_SITE_TITLE}.
          </p>
          <InfoRequest />
        </Card>
      </SliderGrid>
      <TopGrid>
        <Card>
          <BlockHeaderContainer title="My Favourites" />
          <FeaturedProducts />
        </Card>
        <HiddenOnMobileCard>
          {/* <BlockHeaderContainer title='Recent Enquiries' /> */}
          {/* TODO: change to actionType link? */}
          <BlockHeaderContainer
            title="Recent Enquiries"
            actionType="button"
            action={() => history.push("/enquiries")}
            buttonText="View All"
          />
          <EnquiriesTable />
        </HiddenOnMobileCard>
      </TopGrid>
    </div>
  );
};

export default Dashboard;

const HiddenOnMobileCard = styled(Card)`
  @media (max-width: 600px) {
    display: none;
  }
`;

const SliderGrid = styled.div`
  display: grid;
  column-gap: 1em;
  grid-template-columns: 5fr 2fr;
  margin: 1em 0;
  @media (max-width: 1025px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;

const TopGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr;
  column-gap: 1em;
  margin-top: 1em;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
