import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "../features/auth/Login";
import { useAuthContext } from "./context/auth";
import Error404 from "./Error404";
import Settings from "../features/Settings";
import Catalogue from "../features/Catalogue";

import ProductDetails from "../features/Catalogue/ProductDetails";
import AdminDashboard from "../features/AdminArea/Dashboard";
import AdminCatalogue from "../features/AdminArea/Catalogue";
import AdminEvents from "../features/AdminArea/Events";
import AdminEnquiries from "../features/AdminArea/Enquiries";
import UserActivity from "../features/AdminArea/UserActivity";
import ForgotPassword from "../features/auth/ForgotPassword";
import { useEffect } from "react";
import Enquiries from "../features/Enquiries";
import NoAccess from "../features/auth/NoAccess";
import { useMenu } from "./context/menu";
import NotApproved from "../features/AdminArea/NotApproved";
import Dashboard from "../features/Dashboard/";

const Wrapper = ({ location, children }) => {
  const { setShowAdminBar } = useMenu();
  useEffect(() => {
    setShowAdminBar(location?.pathname?.includes("admin-area"));
  }, [location, setShowAdminBar]);
  return children;
};

export default function Router() {
  const auth = useAuthContext();
  const authRoutes = [
    { path: "/dashboard", component: (props) => <Dashboard {...props} /> },
    { path: "/settings", component: (props) => <Settings {...props} /> },
    { path: "/enquiries", component: (props) => <Enquiries {...props} /> },
    { path: "/catalogue", component: (props) => <Catalogue {...props} /> },
    {
      path: "/catalogue/:id",
      component: (props) => <ProductDetails {...props} />,
    },
  ];
  const notApprovedRoutes = [
    { path: "/dashboard", component: (props) => <NotApproved {...props} /> },
  ];

  const adminRoutes = [
    {
      path: "/admin-area/dashboard",
      component: (props) => <AdminDashboard {...{ props }} />,
    },
    {
      path: "/admin-area/user-activity",
      component: (props) => <UserActivity {...{ props }} />,
    },
    {
      path: "/admin-area/catalogue",
      component: (props) => <AdminCatalogue {...{ props }} />,
    },
    {
      path: "/admin-area/enquiries",
      component: (props) => <AdminEnquiries {...{ props }} />,
    },
    {
      path: "/admin-area/events",
      component: (props) => <AdminEvents {...{ props }} />,
    },
  ];

  const createRoutes = (routes) => {
    return routes.map((route, idx) => (
      <Route
        exact
        path={route.path}
        render={(props) => {
          return <Wrapper {...props}>{route.component(props)}</Wrapper>;
        }}
        key={idx}
      />
    ));
  };

  return (
    <Switch>
      <Route exact path="/" render={() => <Login />} />
      <Route exact path="/forgot-password" render={() => <ForgotPassword />} />
      {auth?.allowed &&
        auth?.claims?.isAdmin &&
        auth?.claims?.isApproved &&
        !auth?.claims?.firstLogin &&
        createRoutes(adminRoutes)}
      {auth?.allowed &&
        auth?.claims?.isApproved &&
        !auth?.claims?.firstLogin &&
        createRoutes(authRoutes)}

      {auth?.user && (
        <Route exact path="/no-access" render={() => <NoAccess />} />
      )}
      {(!auth?.claims?.isApproved || auth?.claims?.firstLogin) &&
        createRoutes(notApprovedRoutes)}
      {auth?.user && auth?.allowed !== undefined && (
        <Route
          exact
          render={(props) => (
            <Wrapper {...props}>
              <Error404 />
            </Wrapper>
          )}
        />
      )}
    </Switch>
  );
}
