import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { useProducts } from "../../app/context/catalogue";
import { useHistory } from "react-router-dom";
import { useFirebase } from "../../app/context/Firebase";
import { Card } from "@theme-ui/components";
import { NavLink } from "react-router-dom";
import { Button } from "@theme-ui/components";
import { useUserContext } from "../../app/context/auth";

export default function FeaturedProducts() {
	const { products } = useProducts();
	var history = useHistory();
	const firebase = useFirebase();
	const user = useUserContext();

	const featuredList = useMemo(() => {
		const productIds = user?.brochureFeaturedList
			? user.brochureFeaturedList
			: [];
		const featuredProducts = [];

		productIds.forEach((id) => {
			featuredProducts.push(products.filter((p) => p.id === id)[0]);
		});

		return featuredProducts;
	}, [products, user]);

	//TODO:add to context?
	var openProduct = (id) => {
		firebase.viewProduct(id).then(() => {
			history.push(`/catalogue/${id}`);
		});
	};

	return (
		<div>
			{featuredList.length > 0 ? (
				featuredList.map((p, idx) => (
					<ProductItem
						key={idx}
						onClick={() => openProduct(p.id)}
						style={{ textAlign: "center", height: "100%" }}
						variant="hoverable"
					>
						{p.title}
					</ProductItem>
				))
			) : (
				<div>
					<p>
						<strong>No favourite products</strong>
					</p>
					<p>
						Navigate to Catalogue and click on the star to add the product to
						your favourites list.
					</p>
					<NavLink to="/catalogue">
						<Button>View Catalogue</Button>
					</NavLink>
				</div>
			)}
		</div>
	);
}

const ProductItem = styled(Card)`
	text-align: left !important;
	margin-bottom: 10px;
	padding: 1em !important;
	border: 1px solid #e9e9ed;
	border-bottom: 2px solid #0081c9;
	&:hover {
		cursor: pointer;
	}
`;
