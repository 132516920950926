import React from "react";
import styled from "@emotion/styled";
import { useProducts } from "../../app/context/catalogue";
import { useMemo } from "react";
import ProductCard from "./ProductCard";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import { useUserContext } from "../../app/context/auth";

export default function ProductsList({ category, search, type, brand }) {
	const { products } = useProducts();
	const user = useUserContext();

	const catalogue = useMemo(
		() =>
			products
				? [
						...products
							.filter((p) => user?.brochureFeaturedList?.includes(p.id))
							.filter((p) => !p.isArchived)
							.filter((p) =>
								category && category.value
									? p.category.toLowerCase() === category.value.toLowerCase()
									: true
							)
							.filter((p) =>
								type && type.value
									? p.type.toLowerCase() === type.value.toLowerCase()
									: true
							)
							.filter((p) =>
								search
									? p.title.toLowerCase().includes(search.toLowerCase())
									: true
							)
							.filter((p) =>
								brand && brand.value
									? p.brand?.toLowerCase() === brand.value?.toLowerCase()
									: true
							)
							.sort((a, b) =>
								a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
							),

						...products
							.filter((p) => !user?.brochureFeaturedList?.includes(p.id))
							.filter((p) => !p.isArchived)
							.filter((p) =>
								category && category.value
									? p.category.toLowerCase() === category.value.toLowerCase()
									: true
							)
							.filter((p) =>
								type && type.value
									? p.type.toLowerCase() === type.value.toLowerCase()
									: true
							)
							.filter((p) =>
								search
									? p.title.toLowerCase().includes(search.toLowerCase())
									: true
							)
							.filter((p) =>
								brand && brand.value
									? p.brand?.toLowerCase() === brand.value?.toLowerCase()
									: true
							)
							.sort((a, b) =>
								a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
							),
				  ]
				: [],
		[category, search, products, type, brand, user]
	);

	return (
		<div>
			<BlockHeaderContainer
				title={`Showing ${catalogue.length} product${
					catalogue.length === 1 ? "" : "s"
				}`}
			/>
			<Grid>
				{catalogue.map((t, idx) => (
					<ProductCard key={idx} product={t} />
				))}
			</Grid>
		</div>
	);
}

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	column-gap: 1em;
	row-gap: 1em;
	@media (max-width: 1366px) {
		grid-template-columns: 1fr 1fr 1fr;
		row-gap: 1em;
	}
	@media (max-width: 800px) {
		grid-template-columns: 1fr 1fr;
		row-gap: 1em;
	}

	@media (max-width: 500px) {
		grid-template-columns: 1fr;
		row-gap: 1em;
	}

	padding: 1em;
`;
