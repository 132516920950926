import React, { useState, useMemo } from "react";
import { customStyles } from "../../app/forms/Form";
import styled from "@emotion/styled";
import { useProducts } from "../../app/context/catalogue";
import { useEvents } from "../../app/context/events";
import { categories } from "../../app/context/catalogue/categories";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";
import Select from "react-select";
//import { brands } from "../../app/context/catalogue/brands";
import { useForm } from "../../app/forms/useForm";
import {
  Button,
  Label,
  Input,
  Radio,
  Message,
  Checkbox,
  Textarea,
  Flex,
} from "@theme-ui/components";
import Field from "../../app/DSL/components/form/Field";
import { regions, _salesReps } from "./data";

export default function EnquiryForm({
  initialValues,
  loading,
  handleSubmit,
  error,
  submitText,
  create,
}) {
  const { products } = useProducts();
  const { events } = useEvents();

  const [extraValues, setValues] = useState({
    informationRequired: null,
    productRequired: null,
    documentsRequired: null,
    comments: null,
  });

  const allEvents = useMemo(() => {
    const newEventsArray = events?.map((p) => ({
      label: p.title,
      value: p.title,
    }));
    return [
      {
        label: "General Enquiry",
        value: "General Enquiry",
      },
      ...newEventsArray,
    ];
  }, [events]);

  const allProducts = useMemo(
    () =>
      products
        ? [
            { value: "All", label: "All" },
            ...products
              .filter((p) => !p.isArchived)
              .filter((p) =>
                extraValues?.informationRequired &&
                extraValues?.informationRequired.length > 0
                  ? extraValues?.informationRequired
                      .map((i) => i.toLowerCase())
                      .includes(p?.category?.toLowerCase())
                  : true
              )
              .filter((p) =>
                extraValues?.brandRequired &&
                extraValues?.brandRequired.length > 0
                  ? extraValues?.brandRequired
                      .map((i) => i.toLowerCase())
                      .includes(p?.brand?.toLowerCase())
                  : true
              )

              .sort((a, b) =>
                a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
              )
              .map((p) => ({
                label: p.title,
                value: p.title,
              })),
          ]
        : [],
    [extraValues, products]
  );

  const salesReps = useMemo(
    () =>
      _salesReps
        .map((s) => ({
          label: `${s.firstName} ${s.lastName}`,
          value: `${s.firstName} ${s.lastName}`,
        }))
        .sort((a, b) => (a.value > b.value ? 1 : -1)),
    []
  );

  /* const allBrands = useMemo(() => {
    return Object.entries(brands)
      .map((entry) => {
        const category = entry[0];
        const b = entry[1];
        if (extraValues?.informationRequired?.includes(category)) {
          return b;
        }
      })
      .filter((b) => b !== undefined)
      .reduce((prev, curr) => [...curr, ...prev], []);
  }, [extraValues]); */

  const workPlaces = ["Hospital", "Practice"];

  const documentsRequired = [
    { value: "All", label: "All" },
    { value: "Brochures", label: "Brochures" },
    { value: "Datasheet", label: "Datasheet" },
    { value: "Surgical Technique", label: "Surgical Technique" },
  ];

  const medicalEducation = [
    { value: "All", label: "All" },
    { value: "SawBone Workshop", label: "SawBone Workshop" },
    { value: "BioSkills", label: "BioSkills" },
    { value: "Surgeon to Surgeon Visit", label: "Surgeon to Surgeon Visit" },
    { value: "In Person Event", label: "In Person Event" },
    { value: "Webinar", label: "Webinar" },
  ];

  const formFields = [
    {
      name: "event",
      title: "Attending an event?",
      type: "select",
      options: allEvents,
      validate: (x) => x?.length >= 2,
      error: "Please select a product",
      placeholder: "--- Select ---",
      selectedOption:
        initialValues && initialValues?.event
          ? allEvents.find((r) => initialValues?.event === r.value)
          : null,
    },
    {
      name: "salesRep",
      title: "Sales Representative",
      type: "select",
      options: salesReps,
      validate: (x) => x?.length >= 2,
      error: "Please select a sales rep",
      placeholder: "--- Select ---",
      selectedOption:
        initialValues && initialValues?.salesRep
          ? salesReps.find((r) => initialValues?.salesRep === r.value)
          : null,
    },
    {
      name: "firstName",
      title: "First Name",
      type: "text",
      validate: (x) => x.length >= 2,
      error: "Name must be longer than 2 characters",
      placeholder: "John",
    },
    {
      name: "lastName",
      title: "Last Name",
      type: "text",
      validate: (x) => x.length >= 2,
      error: "Name must be longer than 2 characters",
      placeholder: "Doe",
    },
    {
      name: "phone",
      title: "Phone Number",
      type: "text",
      validate: (x) => x.length >= 2,
      error: "Phone number must be longer than 2 characters",
      placeholder: "000 000 0000",
    },
    {
      name: "email",
      title: "Email",
      type: "email",
      validate: (x) => {
        var re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(x);
      },
      error: "Must be valid email address",
      placeholder: "example@zimmerbiomet.com",
    },
    {
      name: "region",
      title: "Your Region",
      type: "select",
      options: regions,
      validate: (x) => x?.length >= 2,
      error: "Please select a region",
      placeholder: "--- Select ---",
      selectedOption:
        initialValues && initialValues?.region
          ? regions.find((r) => initialValues?.region === r.value)
          : null,
    },
    {
      name: "workPlace",
      title: "Where do you work?",
      type: "radio",
      options: workPlaces,
      validate: (x) => x.length > 1,
      error: "Please select a place of work",
    },
    {
      name: "workPlaceName",
      title: "Practice/Hospital Name",
      type: "text",
      optional: true,
      validate: (x) => true,
      error: "",
    },
    {
      name: "comments",
      title: "Comments",
      type: "textarea",
      validate: (x) => true,
      error: "Comment must be longer than 2 characters",
      placeholder: "",
      optional: true,
    },
  ];

  const [createAnother, setCreateAnother] = useState(true);

  const {
    formValid,
    inputProps,
    formattedValues,
    values,
    reset,
    setAllTouched,
  } = useForm(formFields, initialValues);

  const invalidSubmit = (e) => {
    e.preventDefault();
    setAllTouched();
  };
  const submit = (e) =>
    formValid
      ? handleSubmit(
          e,
          { ...formattedValues(), ...extraValues },
          reset,
          create && createAnother
        )
      : invalidSubmit(e);

  return (
    <StyledForm>
      <form onSubmit={submit}>
        {/* TODO: Clean up */}
        {formFields
          .filter(({ hidden }) => !hidden)
          .map(
            (
              { title, name, placeholder, type, options, readOnly, isMulti },
              idx
            ) => {
              const fieldProps = { name, values, error, title, key: idx };
              switch (type) {
                case "radio":
                  return (
                    <Field {...fieldProps}>
                      <Flex>
                        {options.map((o, idx) => (
                          <InputWrap key={idx}>
                            <Label
                              variant="radioLabel"
                              sx={{
                                textTransform: "capitalize",
                              }}
                            >
                              <Radio
                                {...inputProps(name)}
                                id={o}
                                value={o}
                                checked={values[name].value === o}
                                defaultChecked={
                                  initialValues &&
                                  initialValues[name] &&
                                  o === initialValues[name]
                                }
                              />
                              {o}
                            </Label>
                          </InputWrap>
                        ))}
                      </Flex>
                    </Field>
                  );

                case "select":
                  return (
                    <Field {...fieldProps}>
                      <Select
                        isSearchable={true}
                        styles={customStyles}
                        {...inputProps(name)}
                        options={options}
                        value={values[name].selectedOption}
                        placeholder={placeholder}
                      />
                    </Field>
                  );

                case "textarea":
                  return (
                    <Field {...fieldProps}>
                      <Textarea
                        {...inputProps(name)}
                        placeholder={placeholder}
                      />
                    </Field>
                  );

                case "multiselect":
                  return (
                    <Field {...fieldProps}>
                      <Select
                        styles={customStyles}
                        {...inputProps(name)}
                        options={options}
                        value={values[name].selectedOptions}
                        placeholder={placeholder}
                        isMulti
                      />
                    </Field>
                  );

                default:
                  return (
                    <Field key={idx} {...fieldProps}>
                      <Input {...inputProps(name)} placeholder={placeholder} />
                    </Field>
                  );
              }
            }
          )}
        {/* TODO: extract into component */}
        <InputWrap style={{ margin: "1em 0", gridArea: "informationRequired" }}>
          <Label>What categories are you interested in?</Label>
          <Select
            styles={customStyles}
            isClearable
            isMulti
            options={[{ value: "All", label: "All" }, ...categories]}
            value={categories.filter((r) =>
              extraValues?.informationRequired?.includes(r.value)
            )}
            placeholder="--- Select ---"
            onChange={(options) => {
              if (options?.find((o) => o.value === "All")) {
                setValues((prev) => {
                  return {
                    ...prev,
                    informationRequired: categories
                      .filter((o) => o.value !== "All")
                      .map((o) => o.value),
                  };
                });
              } else {
                setValues((prev) => {
                  return {
                    ...prev,
                    informationRequired: options
                      ? options.map((o) => o.value)
                      : null,
                  };
                });
              }
            }}
          />
        </InputWrap>
        {/* <InputWrap style={{ margin: "1em 0", gridArea: "brandRequired" }}>
          <Label>What brands would you like more information about?</Label>
          <Select
            styles={customStyles}
            isClearable
            isSearchable
            isMulti
            options={allBrands}
            value={allBrands.filter((r) =>
              extraValues?.brandRequired?.includes(r.value)
            )}
            placeholder="--- Select ---"
            onChange={(options) => {
              setValues((prev) => ({
                ...prev,
                brandRequired: options ? options.map((o) => o.value) : null,
              }));
            }}
          />
        </InputWrap> */}
        <InputWrap style={{ margin: "1em 0", gridArea: "productRequired" }}>
          <Label>What products would you like information about?</Label>
          <Select
            styles={customStyles}
            isClearable
            isSearchable
            isMulti
            options={allProducts}
            value={allProducts.filter((r) =>
              extraValues?.productRequired?.includes(r.value)
            )}
            placeholder="--- Select ---"
            onChange={(options) => {
              if (options?.find((o) => o.value === "All")) {
                setValues((prev) => {
                  return {
                    ...prev,
                    productRequired: allProducts
                      .filter((o) => o.value !== "All")
                      .map((o) => o.value),
                  };
                });
              } else {
                setValues((prev) => ({
                  ...prev,
                  productRequired: options ? options.map((o) => o.value) : null,
                }));
              }
            }}
          />
        </InputWrap>
        <InputWrap style={{ margin: "1em 0", gridArea: "documentsRequired" }}>
          <Label>What would you like to receive?</Label>
          <Select
            styles={customStyles}
            isClearable
            isMulti
            options={documentsRequired}
            value={documentsRequired.filter((r) =>
              extraValues?.documentsRequired?.includes(r.value)
            )}
            placeholder="--- Select ---"
            onChange={(options) => {
              if (options?.find((o) => o.value === "All")) {
                setValues((prev) => {
                  return {
                    ...prev,
                    documentsRequired: documentsRequired
                      .filter((o) => o.value !== "All")
                      .map((o) => o.value),
                  };
                });
              } else {
                setValues((prev) => ({
                  ...prev,
                  documentsRequired: options
                    ? options.map((o) => o.value)
                    : null,
                }));
              }
            }}
          />
        </InputWrap>
        <InputWrap style={{ margin: "1em 0", gridArea: "medicalEducation" }}>
          <Label>Would you be interested in attending Medical Education?</Label>
          <Select
            styles={customStyles}
            isClearable
            isMulti
            options={medicalEducation}
            value={medicalEducation.filter((r) =>
              extraValues?.medicalEducation?.includes(r.value)
            )}
            placeholder="--- Select ---"
            onChange={(options) => {
              if (options?.find((o) => o.value === "All")) {
                setValues((prev) => {
                  return {
                    ...prev,
                    medicalEducation: medicalEducation
                      .filter((o) => o.value !== "All")
                      .map((o) => o.value),
                  };
                });
              } else {
                setValues((prev) => ({
                  ...prev,
                  medicalEducation: options
                    ? options.map((o) => o.value)
                    : null,
                }));
              }
            }}
          />
        </InputWrap>
        {create && (
          <InputWrap style={{ gridArea: "checkbox" }}>
            <Label variant="radioLabel">
              <Checkbox
                checked={createAnother}
                onChange={(e) => {
                  setCreateAnother(!createAnother);
                }}
              />
              Create another enquiry?
            </Label>
          </InputWrap>
        )}
        {error && (
          <Message variant="warning" style={{ gridArea: "error" }}>
            {error.message}
          </Message>
        )}

        <InputWrap style={{ gridArea: "button" }}>
          {loading ? (
            <Button disabled={loading}>Loading</Button>
          ) : (
            <Button type="submit" variant={!formValid ? "disabled" : "primary"}>
              {submitText}
            </Button>
          )}
        </InputWrap>
      </form>
    </StyledForm>
  );
}

const StyledForm = styled.div`
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;
    grid-template-rows: auto;
    grid-template-areas:
      "event salesRep"
      "firstName lastName"
      "phone email"
      "workPlace workPlaceName"
      "region region"
      "informationRequired productRequired"
      "documentsRequired medicalEducation"
      "comments comments"
      "checkbox checkbox"
      "error error"
      "button button";
  }
`;
