/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import { useState } from "react";
import { useForm } from "./useForm";
import {
  Button,
  Label,
  Input,
  Radio,
  Message,
  Checkbox,
  Textarea,
} from "@theme-ui/components";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";

import Select from "react-select";
import Field from "../DSL/components/form/Field";
import DateInput from "../DSL/components/form/DateInput";

//TODO: copy from HQ

export default function Form({
  initialValues,
  handleSubmit,
  loading,
  error,
  submitText,
  formFields,
  create,
  event,
  type,
  cancel,
}) {
  const [createAnother, setCreateAnother] = useState(true);

  const { formValid, inputProps, formattedValues, values, reset } = useForm(
    formFields,
    initialValues
  );

  return (
    <form
      onSubmit={(e) =>
        handleSubmit(e, formattedValues(), reset, create && createAnother)
      }
    >
      {formFields
        .filter(({ hidden }) => !hidden)
        .map(
          (
            { title, name, placeholder, type, options, readOnly, isMulti },
            idx
          ) => {
            const fieldProps = { name, values, error, title, key: idx };
            switch (type) {
              case "date":
                return (
                  <Field {...fieldProps}>
                    <DateInput
                      inputProps={inputProps(name)}
                      startDate={values[name].value}
                    />
                  </Field>
                );

              case "radio":
                return (
                  <Field {...fieldProps}>
                    <Flex>
                      {options.map((o, idx) => (
                        <InputWrap key={idx}>
                          <Label
                            variant="radioLabel"
                            sx={{
                              textTransform: "capitalize",
                            }}
                          >
                            <Radio
                              {...inputProps(name)}
                              id={o}
                              value={o}
                              checked={values[name].value === o}
                              defaultChecked={
                                initialValues &&
                                initialValues[name] &&
                                o === initialValues[name]
                              }
                            />
                            {o}
                          </Label>
                        </InputWrap>
                      ))}
                    </Flex>
                  </Field>
                );

              case "select":
                return (
                  <Field {...fieldProps}>
                    <Select
                      isSearchable={true}
                      styles={customStyles}
                      {...inputProps(name)}
                      options={options}
                      value={values[name].selectedOption}
                      placeholder={placeholder}
                    />
                  </Field>
                );

              case "textarea":
                return (
                  <Field {...fieldProps}>
                    <Textarea {...inputProps(name)} placeholder={placeholder} />
                  </Field>
                );

              case "multiselect":
                return (
                  <Field {...fieldProps}>
                    <Select
                      styles={customStyles}
                      {...inputProps(name)}
                      options={options}
                      value={values[name].selectedOptions}
                      placeholder={placeholder}
                      isMulti
                    />
                  </Field>
                );

              default:
                return (
                  <Field key={idx} {...fieldProps}>
                    <Input {...inputProps(name)} placeholder={placeholder} />
                  </Field>
                );
            }
          }
        )}
      {create && (
        <InputWrap style={{ gridArea: "checkbox" }}>
          <Label variant="radioLabel">
            <Checkbox
              checked={createAnother}
              onChange={(e) => {
                setCreateAnother(!createAnother);
              }}
            />
            Create another {type}?
          </Label>
        </InputWrap>
      )}

      {error && (
        <Message variant="warning" style={{ gridArea: "error" }}>
          {error.message}
        </Message>
      )}

      <InputWrap style={{ gridArea: "button" }}>
        {cancel ? (
          <Flex sx={{ justifyContent: "space-between" }}>
            <Button variant="outline" onClick={cancel}>
              Cancel
            </Button>
            {loading ? (
              <Button disabled={loading}>Loading</Button>
            ) : (
              <Button
                type="submit"
                variant={!formValid ? "disabled" : "primary"}
              >
                {submitText}
              </Button>
            )}
          </Flex>
        ) : loading ? (
          <Button disabled={loading}>Loading</Button>
        ) : (
          <Button type="submit" variant={!formValid ? "disabled" : "primary"}>
            {submitText}
          </Button>
        )}
      </InputWrap>
    </form>
  );
}

export const customStyles = {
  control: (provided, state) => ({
    display: "flex",
    alignItems: "center",
    boxShadow: "inset 0 0.0625em 0.125em rgba(10,10,10,.05)",
    backgroundColor: "#fff",
    borderColor: state.isFocused
      ? "var(--theme-ui-colors-tusk-shade-900,#CDCDD6) !important"
      : "var(--theme-ui-colors-tusk-shade-500,#E2E2E7) !important",
    border: "1px solid",
    borderRadius: 4,
    color: "#363636",
    font: "inherit",
    outline: 0,
    position: "realtive",
    zIndex: "20",
  }),
  input: (provided, state) => ({
    ...provided,
    paddingBottom: "calc(.5em - 2px)",
    paddingLeft: "calc(.75em - 2px)",
    paddingRight: "calc(.75em - 2px)",
    paddingTop: "calc(.5em - 2px)",
  }),
  container: (provided, state) => ({
    ...provided,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};
