import React, { useState } from "react";
import styled from "@emotion/styled";
import { Button, Heading, Image, MenuButton } from "@theme-ui/components";
import { useUserContext } from "../../../context/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFirebase } from "../../../context/Firebase";
import { primary } from "../../theme";
import { ImageBlock } from "../elements/styled";
import MobileMenu from "../navigation/MobileMenu";
import { useMenu } from "../../../context/menu";
import { useHistory } from "react-router-dom";

export default function PageHeaderContainer({
	children,
	image,
	showBackButton = false,
}) {
	const user = useUserContext();
	const firebase = useFirebase();
	const { setShowMenu } = useMenu();
	const [menuOpen, setMenuOpen] = useState(false);
	const history = useHistory();

	return (
		<div>
			<Header>
				<Title>
					<MenuButton
						className="mobile"
						onClick={() => setShowMenu((prev) => !prev)}
					/>
					{showBackButton ? (
						<div>
							<BackButton onClick={() => history.goBack()}>
								<FontAwesomeIcon icon={["fas", "chevron-left"]} />
							</BackButton>
						</div>
					) : null}

					{!image && (
						<Image
							className="not-mobile"
							src="/logo.svg"
							width="50px"
							style={{ marginRight: "10px" }}
						/>
					)}
					{image ? children : <Heading as="h1">{children}</Heading>}
				</Title>
				<UserInfoCon>
					<UserMenu>
						<div
							className="dropdown"
							onClick={() => {
								setMenuOpen(!menuOpen);
							}}
							onMouseEnter={() => {
								setMenuOpen(true);
							}}
							onMouseLeave={() => {
								setMenuOpen(false);
							}}
						>
							<div className="menu">
								<ImageBlock
									size="35px"
									border="2px"
									url={
										user && user.profileImageUrl
											? user.profileImageUrl
											: "/user-circle-solid.svg"
									}
								>
									<div className="img"></div>
								</ImageBlock>
								<p>{user ? user.displayName : "Welcome back"}</p>
								<FontAwesomeIcon icon={["fal", "angle-down"]} />
							</div>

							<div
								className="dropdown-content"
								style={{ display: menuOpen ? "block" : "none" }}
							>
								<MenuItem onClick={() => firebase.signOut()}>
									<FontAwesomeIcon icon={["fas", "sign-out"]} /> Logout
								</MenuItem>
							</div>
						</div>
					</UserMenu>
				</UserInfoCon>
			</Header>
			<MobileMenu />
		</div>
	);
}

const Title = styled.div`
	display: flex;
	align-items: center;
	button {
		margin-right: 1em;
	}
`;

export const Header = styled.div`
	margin: 2em 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	height: 75px;
	.mobile {
		display: none;
	}
	h1 {
		color: ${(props) => props.theme.colors.midnight.shade["700"]};
	}
	@media (max-width: 1024px) {
		margin: 0.5em 0;
		h1 {
			font-size: 1.5em;
		}
	}
	@media (max-width: 800px) {
		.mobile {
			display: block;
		}
		grid-template-columns: auto 1fr;
		column-gap: 1em;
	}
`;
export const UserInfoCon = styled.div`
	justify-items: end;
	@media (max-width: 800px) {
		display: none;
	}
`;

export const UserMenu = styled.div`
	display: grid;
	align-items: center;
	justify-content: end;
	grid-template-columns: auto auto;
	column-gap: 1em;
	.dropdown {
		position: relative;
		.menu {
			display: grid;
			align-items: center;
			text-transform: capitalize;
			grid-template-columns: 40px 1fr 10px;
			column-gap: 1em;
			margin: 0.5em;
			p,
			svg {
				font-size: 1.1em;
				color: ${(props) => props.theme.colors.midnight.shade["700"]};
			}
		}
		.dropdown-content {
			position: absolute;
			background-color: ${(props) => props.theme.colors.tusk.shade["100"]};
			width: 100%;
			box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
			z-index: 1;
		}
	}
`;

const MenuItem = styled.div`
	padding: 1em;
	cursor: pointer;
	color: ${(props) => props.theme.colors[primary].shade["500"]};
	svg {
		margin-right: 10px;
	}

	:hover {
		background-color: ${(props) => props.theme.colors[primary].shade["500"]};
		color: ${(props) => props.theme.colors[primary].font["500"]};
	}
`;

const BackButton = styled(Button)`
	background-color: var(--theme-ui-colors-tusk-shade-500, #e2e2e7);
	color: black;
	border: none;
	&:active {
		background-color: var(--theme-ui-colors-tusk-shade-900, #cdcdd6);
	}

	@media (min-width: 800px) {
		display: none;
	}
`;
