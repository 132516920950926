import React from "react";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";
import { Label, Textarea, Button } from "@theme-ui/components";
import Select from "react-select";
import { customStyles } from "../../app/forms/Form";
import { categories } from "../../app/context/catalogue/categories";
import { useFirebase } from "../../app/context/Firebase";
import { useUserContext } from "../../app/context/auth";
import { useProducts } from "../../app/context/catalogue";

const InfoRequest = () => {
  let { products } = useProducts();
  products = products.map((i) => {
    return {
      label: i.title,
      value: i.title,
      category: i.category,
    };
  });
  const [product, setProduct] = useState();
  const [content, setContent] = useState("");
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { addToast } = useToasts();
  const firebase = useFirebase();
  const user = useUserContext();

  const handleSubmit = async (e, formValues) => {
    setLoading(true);
    e.preventDefault();
    //TODO: move to context
    return firebase
      .productEnquiries()
      .doc()
      .set({
        product: product,
        content: content,
        category: category.value,
        createdBy: user.firstName + " " + user.lastName,
        userEmail: user.email,
        dateCreated: new Date(),
      })

      .then(() => {
        addToast("Product enquiry submitted.", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1500,
          transitionDuration: 10,
          transitionState: "entered",
        });
        setProduct(null);
        setCategory(null);
        setContent("");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        setError(error);
        console.log(error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div>
          <InputWrap>
            <Label>Category</Label>
            <Select
              styles={customStyles}
              isClearable
              isSearchable
              options={categories}
              value={category}
              placeholder="Category"
              onChange={(option) => setCategory(option)}
            />
          </InputWrap>
          <InputWrap>
            <Label>Product Name</Label>
            {/* <Input onChange={(e) => setProduct(e.target.value)} /> */}
            <Select
              styles={customStyles}
              isClearable
              isSearchable
              options={
                category
                  ? products.filter((p) => p.category === category.value)
                  : products
              }
              value={product}
              placeholder="Product"
              onChange={(option) => setProduct(option)}
            />
          </InputWrap>
          <InputWrap>
            <Label>Request</Label>
            <Textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              rows={5}
            ></Textarea>
          </InputWrap>
          <InputWrap>
            <Button variant="infoRequest" type="submit">
              Send Message
            </Button>
          </InputWrap>
        </div>
      </div>
    </form>
  );
};

export default InfoRequest;
