import React from "react";
import styled from "@emotion/styled";
import { primary } from "../../theme";
import { NavLink } from "react-router-dom";
import { Image } from "@theme-ui/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthContext, useUserContext } from "../../../context/auth";
import { useMenu } from "../../../context/menu";
import { ImageBlock } from "../elements/styled";
import { useFirebase } from "../../../context/Firebase";

//TODO: extract nav items to file and loop through

export default function MobileMenu() {
  const { claims } = useAuthContext();
  const user = useUserContext();
  const { setShowMenu, showMenu, showAdminBar } = useMenu();
  const firebase = useFirebase();
  return (
    <Menu show={showMenu}>
      <Content>
        <Header>
          <div>
            <div className="user-details">
              <ImageBlock
                size="65px"
                border="2px"
                url={
                  user && user.profileImageUrl
                    ? user.profileImageUrl
                    : "/user-circle-solid.svg"
                }
              >
                <div className="img"></div>
              </ImageBlock>
              <div>
                <h4>{user ? user.displayName : "Welcome back"}</h4>
              </div>
            </div>
          </div>

          <FontAwesomeIcon
            icon={["fal", "times"]}
            size="2x"
            onClick={() => setShowMenu(false)}
          />
        </Header>
        <SmallItems
          onClick={() => {
            setShowMenu(false);
            firebase.signOut();
          }}
        >
          <SmallItem>
            <h1>
              <FontAwesomeIcon icon={["fas", "sign-out"]} /> Logout
            </h1>
          </SmallItem>
        </SmallItems>
        {!showAdminBar ? (
          <TopOptions>
            <IconBox>
              <BoxLink to="/dashboard" onClick={() => setShowMenu(false)}>
                <FontAwesomeIcon icon={["fal", "th"]} size="2x" />{" "}
                <p>Dashboard</p>{" "}
              </BoxLink>
            </IconBox>
            <IconBox>
              <BoxLink to="/catalogue" onClick={() => setShowMenu(false)}>
                <FontAwesomeIcon icon={["fal", "book-open"]} size="2x" />
                <p>Catalogue</p>
              </BoxLink>
            </IconBox>

            <IconBox>
              <BoxLink to="/enquiries" onClick={() => setShowMenu(false)}>
                <FontAwesomeIcon icon={["fal", "user-md-chat"]} size="2x" />
                <p>Enquiries</p>
              </BoxLink>
            </IconBox>
          </TopOptions>
        ) : (
          <TopOptions>
            <IconBox>
              <BoxLink
                to="/admin-area/dashboard"
                onClick={() => setShowMenu(false)}
              >
                <FontAwesomeIcon icon={["fal", "th"]} size="lg" />
                <p>Dashboard</p>
              </BoxLink>
            </IconBox>
            <IconBox>
              <BoxLink
                to="/admin-area/catalogue"
                onClick={() => setShowMenu(false)}
              >
                <FontAwesomeIcon icon={["fal", "book-open"]} size="lg" />
                <p>Catalogue</p>
              </BoxLink>
            </IconBox>
            <IconBox>
              <BoxLink
                to="/admin-area/enquiries"
                onClick={() => setShowMenu(false)}
              >
                <FontAwesomeIcon icon={["fal", "user-md-chat"]} size="lg" />
                <p>Enquiries</p>
              </BoxLink>
            </IconBox>
            <IconBox>
              <BoxLink
                to="/admin-area/events"
                onClick={() => setShowMenu(false)}
              >
                <FontAwesomeIcon icon={["fal", "calendar"]} size="lg" />
                <p>Events</p>
              </BoxLink>
            </IconBox>
          </TopOptions>
        )}
        <BotOptions>
          <IconBox>
            <a
              href={
                claims?.isAdmin
                  ? "http://reconise.com/help-admin"
                  : "http://reconise.com/help"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <BoxButton>
                <FontAwesomeIcon icon={["fas", "question-circle"]} size="2x" />
                <p>Need help?</p>
              </BoxButton>
            </a>
          </IconBox>
          {claims?.isAdmin &&
            (!showAdminBar ? (
              <IconBox>
                <BoxLink to="/admin-area/dashboard">
                  <FontAwesomeIcon icon={["fas", "lock"]} size="2x" />
                  <p>Admin Area</p>
                </BoxLink>
              </IconBox>
            ) : (
              <IconBox>
                <BoxLink to="/dashboard">
                  <FontAwesomeIcon icon={["fas", "th"]} size="2x" />
                  <p>Back to Home</p>
                </BoxLink>
              </IconBox>
            ))}
          <IconBox>
            <a href={process.env.REACT_APP_HQ_LINK}>
              <BoxButton>
                <Image src="/assets/HQ-icon.png" />
                <p>HQ</p>
              </BoxButton>
            </a>
          </IconBox>
        </BotOptions>{" "}
      </Content>
    </Menu>
  );
}
const Menu = styled.div`
  z-index: 50;
  width: 350px;
  position: fixed;
  left: ${(props) => (props.show ? 0 : "-350px")};
  top: 0;
  min-height: 100%;
  background-color: ${(props) => props.theme.colors[primary].shade["500"]};
  transition: 0.5s;
`;

const Content = styled.div`
  display: grid;
  grid-template-rows: 115px auto 1fr 1fr;
  background-color: ${(props) => props.theme.colors[primary].shade["600"]};
  width: 350px;
  height: 100vh;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Header = styled.div`
  margin: 0 2em;
  align-self: center;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1em;
  align-items: center;
  * {
    color: ${(props) => props.theme.colors[primary].font["700"]};
  }
  .user-details {
    display: grid;
    align-items: center;
    text-transform: capitalize;
    grid-template-columns: 70px 1fr;
    column-gap: 1em;
    margin: 0.5em;
    h4 {
      font-size: 1.1em;
      margin: 0;
      margin-bottom: 10px;
    }
  }
`;

const TopOptions = styled.div`
  display: grid;
  align-content: start;
  border-top: 1px solid ${(props) => props.theme.colors[primary].shade["800"]};
`;

const BotOptions = styled.div`
  display: grid;
  align-items: end;
  align-self: end;
  border-top: 1px solid ${(props) => props.theme.colors[primary].shade["800"]};
`;

const IconBox = styled.div`
  a[aria-current="page"] {
    background: ${(props) => props.theme.colors[primary].shade["800"]};
  }
  cursor: pointer;
  height: 75px;
  border-bottom: 1px solid
    ${(props) => props.theme.colors[primary].shade["800"]};
`;

const BoxLink = styled(NavLink)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1em;
  align-items: center;
  padding: 0 2em;
  * {
    color: ${(props) => props.theme.colors[primary].font["700"]};
  }
  svg {
  }
  p {
    margin: 0;
  }
`;

const SmallItem = styled.div`
  h1 {
    svg {
      margin-right: 5px;
    }
    font-size: 1em;
    margin: 0;
  }
  * {
    color: ${(props) => props.theme.colors[primary].font["700"]};
  }
`;

const SmallItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 2em;
  border-top: 1px solid ${(props) => props.theme.colors[primary].shade["800"]};
  background: ${(props) => props.theme.colors[primary].shade["400"]};
  a:last-child {
    padding-left: 1em;
    border-left: 1px solid
      ${(props) => props.theme.colors[primary].shade["800"]};
  }
  a:first-child {
    padding-right: 1em;
  }
`;

const BoxButton = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1em;
  align-items: center;
  padding: 0 2em;

  * {
    color: ${(props) => props.theme.colors[primary].font["700"]};
  }
  svg {
  }
  p {
    margin: 0;
  }
  img {
    align-self: center;
    margin-top: 2px;
    width: 32px;
  }
`;
