export const categories = [
  {
    label: "Cement",
    value: "cement",
  },
  {
    label: "Extremities",
    value: "extremities",
  },
  {
    label: "Hips",
    value: "hips",
  },
  {
    label: "Knees",
    value: "knees",
  },
  {
    label: "Spine",
    value: "spine",
  },
  {
    label: "Sportsmed",
    value: "sportsmed",
  },
  {
    label: "Surgical",
    value: "surgical",
  },
  {
    label: "Trauma",
    value: "trauma",
  },
  {
    label: "Robotics",
    value: "robotics",
  },
  {
    label: "ZBEdge",
    value: "zBEdge",
  },
  {
    label: "Technology",
    value: "technology",
  },
  {
    label: "Shoulder",
    value: "shoulder",
  },
  {
    label: "Trabecular Metal",
    value: "trabecular Metal",
  },
];
